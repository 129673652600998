import { useEffect, useMemo, useState } from 'react';
import {
    ClaimRequestNomaRepository,
    UseAsyncStatus,
    useClaimRequest as useClaimRequestWithRepository,
    useHref,
} from '@barkibu/noma-commons';
import InvoicesDetails from './InvoicesDetails';
import { useInvoicesDetails } from './useInvoicesDetails';
import CostLine from '../../../domain/entities/CostLine';
import { DigitisationStatus } from '../../../domain/values/DigitisationStatus';
import { CreateCostLineDto } from '../../../infraestructure/repositories/invoice/dtos/CreateCostLineDto';
import { UpdateCostLineDto } from '../../../infraestructure/repositories/invoice/dtos/UpdateCostLineDto';
import { UpdateInvoiceCostLinesDto } from '../../../infraestructure/repositories/invoice/dtos/UpdateInvoiceCostLinesDto';
import { useIncidentDetails } from '../incident-details/useIncidentDetails';

type InvoicesDetailsContainerProps = {
    claimRequestKey: string;
};

const InvoicesDetailsContainer = ({ claimRequestKey }: InvoicesDetailsContainerProps) => {
    const useClaimRequest = useMemo(() => useClaimRequestWithRepository(ClaimRequestNomaRepository), []);

    const [loading, setLoading] = useState(true);
    const { toClaimInbox, toVetView, toDataEntry, toCoverages } = useHref();
    const { claimRequestDetails } = useClaimRequest(claimRequestKey);
    const { incidentDetails, incidentDetailsFetchStatus, completeDataEntry } = useIncidentDetails(claimRequestKey);

    const {
        invoicesDetails,
        invoicesDetailsFetchStatus,
        updateInvoiceCostLines,
        updateClinic,
        updateDate,
        updateDiscount,
        updateNumber,
        updateTaxIdNumber,
        refreshInvoiceDetails,
        isSyncing,
    } = useInvoicesDetails(claimRequestKey);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (claimRequestKey) {
            const ocrFinish = invoicesDetails?.invoices.every(
                (invoice) => invoice.digitisationStatus !== DigitisationStatus.PROCESSING
            );
            setLoading(
                (invoicesDetailsFetchStatus != UseAsyncStatus.SUCCESS &&
                    incidentDetailsFetchStatus != UseAsyncStatus.SUCCESS) ||
                    !ocrFinish
            );
            if (!ocrFinish) {
                timeout = setInterval(() => {
                    refreshInvoiceDetails();
                }, 5000);
            }
        }

        return () => {
            clearInterval(timeout);
        };
    }, [invoicesDetails, invoicesDetailsFetchStatus, incidentDetailsFetchStatus]);

    const onDataEntryFinished = async (moveToVetView: boolean) => {
        try {
            setLoading(true);
            if (!incidentDetails?.readOnly) {
                await completeDataEntry(!moveToVetView);
            }
            if (!moveToVetView) {
                return toClaimInbox();
            }
            if (claimRequestDetails?.isPrevention()) {
                toCoverages(claimRequestKey);
            } else {
                toVetView(claimRequestKey);
            }
        } catch (e) {
            setLoading(false);
            throw e;
        }
    };

    const onUpdateCostLines = async (invoiceKey: string, costLines: CostLine[]) => {
        const updateInvoiceCostLinesDto: UpdateInvoiceCostLinesDto = {
            key: invoiceKey,
            costLines: costLines.map((costLine): CreateCostLineDto | UpdateCostLineDto => ({
                key: costLine.key,
                concept: costLine.concept,
                vat: costLine.VAT,
                units: costLine.units,
                priceInCents: costLine.priceInCents,
            })),
        };
        await updateInvoiceCostLines(invoiceKey, updateInvoiceCostLinesDto);
    };
    const onRedirectToIncident = async () => {
        toDataEntry(claimRequestKey);
    };

    return (
        <InvoicesDetails
            invoicesDetails={invoicesDetails}
            onDataEntryFinished={onDataEntryFinished}
            onRedirectToIncident={onRedirectToIncident}
            loading={loading}
            readOnly={incidentDetails?.readOnly || false}
            onUpdateClinic={updateClinic}
            onUpdateCostLines={onUpdateCostLines}
            onUpdateDate={updateDate}
            onUpdateDiscount={updateDiscount}
            onUpdateNumber={updateNumber}
            onUpdateTaxIdNumber={updateTaxIdNumber}
            disableActions={isSyncing}
            claimRequestKey={claimRequestKey}
        />
    );
};

export default InvoicesDetailsContainer;
