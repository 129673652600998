import { useEffect, useMemo, useState } from 'react';
import {
    BlockingLoader,
    ClaimRequestNomaRepository,
    ClaimRequestStatus,
    NoteScope,
    NoteTypeValue,
    UseAsyncStatus,
    useClaimRequest as useClaimRequestWithRepository,
    useClaimRequestStatus,
    useHref,
    useNotes,
} from '@barkibu/noma-commons';
import { useIncidentDetails } from '@barkibu/noma-data-entry';
import { TFunction } from 'i18next';
import CoveragePage, { CoveragePageProps } from './CoveragePage';
import { useAdjustments as useAdjustmentsWithRepository } from '../../../application/adjustment/useAdjustments';
import { useCompleteVetAnalysis } from '../../../application/assessment/useCompleteVetAnalysis';
import { Adjustment } from '../../../domain/adjustment/Adjustment';
import { AdjustmentRecommendation } from '../../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentNomaRepository } from '../../../infrastructure/adjustment/AdjustmentNomaRepository';
import { AssessmentNomaRepository } from '../../../infrastructure/assessment/AssessmentNomaRepository';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';

type CoveragePageContainerProps = {
    claimRequestKey: string;
};

export const transformAdjustments = (
    adjustments: Adjustment[] | undefined,
    t: TFunction
): CoveragePageProps['adjustments'] | undefined =>
    adjustments?.map((adjustment) => ({
        key: adjustment.key,
        name: adjustment.invoice.number,
        date: adjustment.invoice.issueDate,
        discountInCents: adjustment.invoice.discountInCents,
        warnings: adjustment.warnings
            .filter((warning) => warning.showWarning)
            .map((warning) => ({
                type: warning.type,
                description: t(`coverages.warning.${warning.type}`),
                payload: warning.payload?.key,
            })),
        concepts: adjustment.concepts.map((concept) => ({
            key: concept.key,
            concept: concept.description,
            costInCents: concept.priceInCents,
            recommendation:
                concept.recommendation != null
                    ? {
                          key: concept.recommendation.key,
                          cover: concept.recommendation.cover,
                          description: concept.recommendation.description,
                      }
                    : undefined,
        })),
    }));

const CoveragePageContainer = ({ claimRequestKey }: CoveragePageContainerProps) => {
    const { t } = useModuleTranslation();
    const repository = useMemo(() => AdjustmentNomaRepository(), []);
    const useAdjustments = useMemo(() => useAdjustmentsWithRepository(AssessmentNomaRepository, repository), []);
    const useClaimRequest = useMemo(() => useClaimRequestWithRepository(ClaimRequestNomaRepository), []);
    const { notes } = useNotes(NoteScope.CLAIM_REQUEST, claimRequestKey);

    const { to, toInvoiceDigitisation, goBack } = useHref();
    const [adjustments, setAdjustments] = useState<Adjustment[] | undefined>();
    const [isFinalisingTheReview, setIsFinalisingTheReview] = useState(false);
    const {
        adjustments: adjustmentValues,
        adjustmentFetchStatus,
        changeConceptRecommendation,
        changeAdjustmentConceptRecommendationStatus,
        syncError,
        findAdjustmentDuplicatedClaimRequests,
    } = useAdjustments(claimRequestKey);
    const { completeVetAnalysis } = useCompleteVetAnalysis(claimRequestKey);
    const { incidentDetails, incidentDetailsFetchStatus } = useIncidentDetails(claimRequestKey);
    const { claimRequestDetails } = useClaimRequest(claimRequestKey);
    const { markStatusAsCreated } = useClaimRequestStatus(claimRequestKey);

    const [containerError, setContainerError] = useState<Error | undefined>();
    const readOnly = incidentDetails?.status.description !== ClaimRequestStatus.READY_FOR_VET_ANALYSIS;

    useEffect(() => {
        setContainerError(syncError);
    }, [syncError]);

    useEffect(() => {
        if (adjustmentValues != null) {
            setAdjustments(adjustmentValues);
        }
    }, [adjustmentValues]);

    const transformedData = transformAdjustments(adjustments, t);

    const updateAdjustmentsWithSelectedRecommendation = (
        conceptKey: string,
        recommendation: AdjustmentRecommendation
    ) => {
        setAdjustments(
            (prevAdjustments) =>
                prevAdjustments?.map((adjustment) => {
                    return {
                        ...adjustment,
                        concepts: adjustment.concepts.map((concept) => {
                            return concept.key !== conceptKey
                                ? concept
                                : {
                                      ...concept,
                                      recommendation,
                                  };
                        }),
                    };
                })
        );
    };

    const onRecommendationSelected: CoveragePageProps['onRecommendationSelected'] = ({
        conceptKey,
        recommendation,
    }) => {
        updateAdjustmentsWithSelectedRecommendation(conceptKey, recommendation);
        changeConceptRecommendation(conceptKey, recommendation.key, recommendation.cover);
    };

    const onCompleteRevision = async (note?: string) => {
        try {
            if (!readOnly) {
                setIsFinalisingTheReview(true);
                await completeVetAnalysis(note);
            }
            to(`/claim-requests/${claimRequestKey}/summary`);
        } catch (e) {
            if (e instanceof Error) setContainerError(e);
            setIsFinalisingTheReview(false);
        }
    };

    const showLoader =
        isFinalisingTheReview ||
        adjustmentFetchStatus !== UseAsyncStatus.SUCCESS ||
        incidentDetailsFetchStatus !== UseAsyncStatus.SUCCESS ||
        transformedData == null ||
        transformedData.length == 0;

    const onGoBack = async () => {
        if (claimRequestDetails?.isPrevention()) {
            await markStatusAsCreated();
            toInvoiceDigitisation(claimRequestKey);
        } else {
            goBack();
        }
    };

    return showLoader ? (
        <BlockingLoader />
    ) : (
        <CoveragePage
            claimRequestKey={claimRequestKey}
            claimRequestDetails={claimRequestDetails!}
            adjustments={transformedData ?? []}
            countryCode={incidentDetails?.countryCode}
            petName={incidentDetails?.petName}
            petParentNotes={notes.filter((note) => note.type == NoteTypeValue.PET_PARENT)}
            onCompleteRevision={onCompleteRevision}
            isSyncing={changeAdjustmentConceptRecommendationStatus === UseAsyncStatus.PENDING}
            onRecommendationSelected={onRecommendationSelected}
            onFindDuplicatedInvoiceRelated={findAdjustmentDuplicatedClaimRequests}
            readOnly={readOnly}
            syncError={containerError}
            onGoBack={onGoBack}
            goBackText={
                claimRequestDetails?.isPrevention()
                    ? t('coverages.back_to_invoice_digitisation')
                    : t('coverages.back_to_vet_view')
            }
        />
    );
};

export default CoveragePageContainer;
