import { useEffect, useState } from 'react';
import { ButtonVariant, Dialog } from '@barkibu/noma-commons';
import InvoiceForm from './invoice-form/InvoiceForm';
import InvoiceHeader from './invoice-header/InvoiceHeader';
import CostLine from '../../../../domain/entities/CostLine';
import { InvoicesDetails as InvoicesDetailsDomain } from '../../../../domain/entities/InvoicesDetails';
import { useModuleTranslation } from '../../../../utils/useModuleTranslation';
import './invoice-body.scss';

export interface InvoiceBodyProps {
    invoicesDetails: InvoicesDetailsDomain;
    readOnly?: boolean;
    onUpdateClinic: (key: string, clinic: string) => void;
    onUpdateCostLines: (key: string, costLines: CostLine[]) => void;
    onUpdateDate: (key: string, date: Date) => void;
    onUpdateDiscount: (key: string, discount: number) => void;
    onUpdateNumber: (key: string, number: string) => void;
    onUpdateTaxIdNumber: (key: string, taxIdNumber: string) => void;
    isFullCoverage?: boolean;
    claimRequestKey?: string;
}

const InvoiceBody = ({
    invoicesDetails,
    readOnly = false,
    onUpdateClinic,
    onUpdateCostLines,
    onUpdateDate,
    onUpdateDiscount,
    onUpdateNumber,
    onUpdateTaxIdNumber,
    isFullCoverage = false,
    claimRequestKey,
}: InvoiceBodyProps) => {
    const { t } = useModuleTranslation();
    const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState<number>(0);
    const [selectedInvoiceIndex, setSelectedInvoiceIndex] = useState<number>(0);
    const [selectedInvoice, setSelectedInvoice] = useState(invoicesDetails.invoices[currentInvoiceIndex]);
    useEffect(() => {
        setCurrentInvoiceIndex(selectedInvoiceIndex);
        setSelectedInvoice(invoicesDetails.invoices[selectedInvoiceIndex]);
    }, [selectedInvoiceIndex]);

    useEffect(() => {
        setSelectedInvoice(invoicesDetails.invoices[selectedInvoiceIndex]);
    }, [invoicesDetails]);

    return (
        <>
            {!selectedInvoice && <h3>{t('invoice.nothing_to_digitise')}</h3>}
            {selectedInvoice && (
                <InvoiceForm
                    key={selectedInvoice.key}
                    invoice={selectedInvoice}
                    readOnly={readOnly}
                    onUpdateClinic={onUpdateClinic}
                    onUpdateCostLines={onUpdateCostLines}
                    onUpdateDiscount={onUpdateDiscount}
                    isFullCoverage={isFullCoverage}
                >
                    <InvoiceHeader
                        readOnly={readOnly}
                        selectedInvoiceIndex={selectedInvoiceIndex}
                        invoiceCollectionLength={invoicesDetails?.invoices?.length ?? 0}
                        handlePreviousInvoiceClick={() => setSelectedInvoiceIndex(selectedInvoiceIndex - 1)}
                        handleNextInvoiceClick={() => setSelectedInvoiceIndex(selectedInvoiceIndex + 1)}
                        invoice={selectedInvoice}
                        onUpdateDate={onUpdateDate}
                        onUpdateNumber={onUpdateNumber}
                        onUpdateTaxIdNumber={onUpdateTaxIdNumber}
                        claimRequestKey={claimRequestKey}
                    />
                </InvoiceForm>
            )}
            <Dialog
                isOpen={currentInvoiceIndex != selectedInvoiceIndex}
                text={t('invoice.confirm_exit_with_changes')}
                actions={[
                    {
                        text: t('modal.continue', { ns: 'commons' }),
                        variantName: ButtonVariant.DANGER,
                        onClick: () => {
                            setCurrentInvoiceIndex(selectedInvoiceIndex);
                        },
                    },
                    {
                        text: t('modal.cancel', { ns: 'commons' }),
                        variantName: ButtonVariant.SECONDARY,
                        onClick: () => {
                            setSelectedInvoiceIndex(currentInvoiceIndex);
                        },
                    },
                ]}
                onClose={() => {
                    setSelectedInvoiceIndex(currentInvoiceIndex);
                }}
            />
        </>
    );
};

export default InvoiceBody;
