import { useMemo } from 'react';
import CostLine from '../../../../../domain/entities/CostLine';

export default function useTotals(costLines: CostLine[], discount: number) {
    const [totalRequested, totalReimbursable] = useMemo(() => {
        let totalRequested =
            costLines.reduce((total: number, costLine: CostLine) => {
                const lineCost = costLine.priceInUnits() * (1 + costLine.VAT) * costLine.units;
                return total + Math.round(lineCost * 100) / 100;
            }, 0) - (discount ?? 0);

        if (totalRequested < 0) totalRequested = 0;

        const totalRequestedRounded = Math.round(totalRequested * 100) / 100;

        const totalReimbursableRounded = Math.round(totalRequestedRounded * 100) / 100;

        return [totalRequestedRounded, totalReimbursableRounded];
    }, [costLines, discount]);

    return {
        totalRequested,
        totalReimbursable,
    };
}
