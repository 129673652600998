import {
    ClaimRequest,
    ClaimRequestDto,
    claimRequestDtoToDomain,
    CollectionEntityResponse,
    flattenEntity,
    OperationMethod,
    processFetchOperation,
    SingleEntityResponse,
} from '@barkibu/noma-commons';
import { InvoiceDto } from './dtos/InvoiceDto';
import { UpdateInvoiceCostLinesDto } from './dtos/UpdateInvoiceCostLinesDto';
import { toDomain } from './InvoiceMapper';
import Invoice from '../../../domain/entities/Invoice';
import { InvoiceRepository } from '../../../domain/repositories/InvoiceRepository';

export class InvoiceNomaRepository implements InvoiceRepository {
    findByIncidentKey(incidentKey: string): Promise<Invoice[]> {
        return processFetchOperation(`/v1/incidents/${incidentKey}/invoices`, OperationMethod.GET, {
            params: { include: 'supportingDocument' },
        }).then((dtos: CollectionEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entities = dtos.data.map((it) => {
                return flattenEntity(it, dtos.included || []);
            });
            return entities.map((it) => toDomain(it));
        });
    }

    updateCostLines(invoiceKey: string, updateInvoiceCostLinesDto: UpdateInvoiceCostLinesDto): Promise<Invoice> {
        return processFetchOperation(`/v1/invoices/${invoiceKey}/cost-lines`, OperationMethod.POST, {
            params: { include: 'supportingDocument' },
            body: JSON.stringify(updateInvoiceCostLinesDto),
        }).then((dto: SingleEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    updateDate(invoiceKey: string, date: Date): Promise<Invoice> {
        return processFetchOperation(`/v1/invoices/${invoiceKey}/date`, OperationMethod.POST, {
            params: { include: 'supportingDocument' },
            body: JSON.stringify({ date: date }),
        }).then((dto: SingleEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    updateNumber(invoiceKey: string, number: string): Promise<Invoice> {
        return processFetchOperation(`/v1/invoices/${invoiceKey}/number`, OperationMethod.POST, {
            params: { include: 'supportingDocument' },
            body: JSON.stringify({ number: number }),
        }).then((dto: SingleEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    updateTaxIdNumber(invoiceKey: string, taxIdNumber: string): Promise<Invoice> {
        return processFetchOperation(`/v1/invoices/${invoiceKey}/tax-id-number`, OperationMethod.POST, {
            params: { include: 'supportingDocument' },
            body: JSON.stringify({ taxIdNumber: taxIdNumber }),
        }).then((dto: SingleEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    updateClinic(invoiceKey: string, clinic: string): Promise<Invoice> {
        return processFetchOperation(`/v1/invoices/${invoiceKey}/clinic`, OperationMethod.POST, {
            params: { include: 'supportingDocument' },
            body: JSON.stringify({ clinic: clinic }),
        }).then((dto: SingleEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    updateDiscount(invoiceKey: string, discount: number): Promise<Invoice> {
        return processFetchOperation(`/v1/invoices/${invoiceKey}/discount`, OperationMethod.POST, {
            params: { include: 'supportingDocument' },
            body: JSON.stringify({ discount: discount }),
        }).then((dto: SingleEntityResponse<InvoiceDto, 'supportingDocument'>) => {
            const entity = flattenEntity(dto.data, dto.included || []);
            return toDomain(entity);
        });
    }

    findInvoiceRelatedClaimRequests(taxIdNumber: string, number: string): Promise<ClaimRequest[]> {
        return processFetchOperation(`/v1/invoices/related-claims`, OperationMethod.GET, {
            params: { taxIdNumber: taxIdNumber, number: number },
        }).then((dtos: CollectionEntityResponse<ClaimRequestDto>) => {
            const entities = dtos.data.map((it) => {
                return flattenEntity(it, dtos.included || []);
            });
            return entities.map((it) => claimRequestDtoToDomain(it));
        });
    }
}
