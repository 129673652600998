import { Input } from '@barkibu/noma-commons';
import useTotals from './useTotals';
import CostLine from '../../../../../domain/entities/CostLine';
import { toCents } from '../../../../../utils/numberUtils';
import { useModuleTranslation } from '../../../../../utils/useModuleTranslation';
import './invoice-totals.scss';

type InvoiceTotalsProps = {
    clinic: string;
    costLines: CostLine[];
    discount: number;
    handleClinicChange: (clinic: string) => void;
    handleClinicTouched: () => void;
    handleDiscountChange: (discount: number) => void;
    handleDiscountTouched: () => void;
    readOnly: boolean;
};

const InvoiceTotals = ({
    readOnly,
    clinic,
    discount,
    costLines,
    handleClinicChange,
    handleClinicTouched,
    handleDiscountChange,
    handleDiscountTouched,
}: InvoiceTotalsProps) => {
    const discountInUnits = discount / 100;
    const { t } = useModuleTranslation();
    const { totalRequested, totalReimbursable } = useTotals(costLines, discountInUnits);

    const totalRequestedFormat = Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
        totalRequested
    );
    const totalReimbursableFormat = Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
        totalReimbursable
    );

    const onDiscountChange = (discount: string) => {
        handleDiscountChange(toCents(Number(discount)));
    };

    return (
        <div className={'invoice-details'}>
            <h3>{t('invoice.totals')}</h3>

            <div className="invoice-details__totals">
                <div className="invoice-details__totals_row">
                    <Input
                        name="providerName"
                        value={clinic}
                        onChange={handleClinicChange}
                        setTouched={handleClinicTouched}
                        label={t('invoice.clinic')}
                        disabled={readOnly}
                    ></Input>
                </div>
                <div className="invoice-details__totals_row">
                    <Input
                        name="discount"
                        type="number"
                        suffix="€"
                        value={discountInUnits}
                        onChange={onDiscountChange}
                        setTouched={handleDiscountTouched}
                        label={t('invoice.discount')}
                        disabled={readOnly}
                    ></Input>
                </div>
                <div className="invoice-details__totals_row">
                    <span className="header">{t('invoice.total_requested')}</span>
                    <span className="total_price">{totalRequestedFormat}</span>
                </div>
                <div className="invoice-details__totals_row">
                    <span className="header">{t('invoice.total_payable')}</span>
                    <span className="total_price">{totalReimbursableFormat}</span>
                </div>
            </div>
        </div>
    );
};

export default InvoiceTotals;
