import { ClaimRequestDto, claimRequestDtoToDomain } from '@barkibu/noma-commons';
import { AdjustmentBillableConceptDto } from './response/AdjustmentBillableConceptDto';
import { AdjustmentDenialReasonDto } from './response/AdjustmentDenialReasonDto';
import { AdjustmentDto } from './response/AdjustmentDto';
import { AdjustmentInvoiceDto } from './response/AdjustmentInvoiceDto';
import { AdjustmentWarningDto } from './response/AdjustmentWarningDto';
import { Adjustment } from '../../domain/adjustment/Adjustment';
import { AdjustmentBillableConcept } from '../../domain/adjustment/AdjustmentBillableConcept';
import { AdjustmentInvoice } from '../../domain/adjustment/AdjustmentInvoice';
import { AdjustmentRecommendation } from '../../domain/adjustment/AdjustmentRecommendation';
import { AdjustmentWarning } from '../../domain/adjustment/AdjustmentWarning';
import { AdjustmentWarningType } from '../../domain/values/AdjustmentWarningType';

export const toDomain = (adjustmentDto: AdjustmentDto): Adjustment => {
    return {
        key: adjustmentDto.key,
        warnings: adjustmentDto.warnings.map(warningToDomain),
        invoice: invoiceToDomain(adjustmentDto.invoice),
        concepts: adjustmentDto.concepts.map((concept) => billableConceptToDomain(concept)),
    };
};

const warningToDomain = (adjustmentWarningDto: AdjustmentWarningDto): AdjustmentWarning => {
    return {
        type: AdjustmentWarningType[adjustmentWarningDto.type as keyof typeof AdjustmentWarningType],
        showWarning: adjustmentWarningDto.showWarning,
        payload: adjustmentWarningDto.payload, // This should have a dedicated mapper if we add more warnings with payload
    };
};

const invoiceToDomain = (invoice: AdjustmentInvoiceDto): AdjustmentInvoice => {
    return {
        key: invoice.key,
        issueDate: new Date(invoice.issueDate),
        number: invoice.number,
        taxIdNumber: invoice.taxIdNumber,
        discountInCents: invoice.discountInCents,
    };
};

const billableConceptToDomain = (billableConcept: AdjustmentBillableConceptDto): AdjustmentBillableConcept => {
    return {
        key: billableConcept.key,
        description: billableConcept.concept,
        priceInCents: billableConcept.totalAmountInCents,
        recommendation: billableConcept.recommendation,
    };
};

export const mapCoverage = (
    key: string,
    claimRequestWithCoverageUsages: ClaimRequestDto[],
    coveragePercentage?: number,
    parentKey?: string,
    usageLimit?: number
): AdjustmentRecommendation => {
    return {
        key: key,
        coveragePercentage: coveragePercentage,
        description:
            parentKey != null
                ? formatRecommendationKey(parentKey) + ' - ' + formatRecommendationKey(key)
                : formatRecommendationKey(key),
        cover: true,
        usageLimit: usageLimit,
        claimRequestWithCoverageUsages: claimRequestWithCoverageUsages.map((claimRequest) =>
            claimRequestDtoToDomain(claimRequest)
        ),
    };
};

export const mapDenialReason = (denialReason: AdjustmentDenialReasonDto): AdjustmentRecommendation => {
    return {
        key: denialReason.key,
        description: formatRecommendationKey(denialReason.key),
        cover: false,
    };
};

const formatRecommendationKey = (key: string) => {
    const keyWithoutUnderscores = key.replaceAll('_', ' ');
    return keyWithoutUnderscores.charAt(0).toUpperCase() + keyWithoutUnderscores.slice(1).toLowerCase();
};
