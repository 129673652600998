import { ClaimRequestStatus } from '../../../domain/values';
import { Chip, ChipType } from '../../../ui/atoms';
import { formatCurrency } from '../../utils/localeUtils';
import { useModuleTranslation } from '../../utils/useModuleTranslation';

const claimStatusColorMap = new Map(
    Object.entries({
        [ClaimRequestStatus.CREATED]: 'grey',
        [ClaimRequestStatus.READY_FOR_VET_ANALYSIS]: 'blue',
        [ClaimRequestStatus.MISSING_INFO]: 'yellow',
        [ClaimRequestStatus.PENDING_DATA_REVIEW]: 'orange',
        [ClaimRequestStatus.ON_HOLD]: 'purple',
        [ClaimRequestStatus.VET_ANALYSIS_COMPLETED]: 'blue',
        [ClaimRequestStatus.PENDING_MUNICH_RE]: 'yellow',
        [ClaimRequestStatus.DISBURSEMENT_REGISTERED_IN_PAS]: 'green',
        [ClaimRequestStatus.DISBURSEMENT_ACCEPTED_BY_PAS]: 'greenish',
        [ClaimRequestStatus.CLOSED]: 'red',
    })
);

export interface ClaimStatusChipProps {
    status: ClaimRequestStatus;
    details?: string | string[];
    amountRefunded?: number;
    colored?: boolean;
    onClick?: () => void;
}

export default function ClaimStatusChip({
    status,
    details,
    amountRefunded,
    colored = false,
    onClick,
}: ClaimStatusChipProps) {
    const { t } = useModuleTranslation();
    const statusText =
        details && status === ClaimRequestStatus.CLOSED
            ? t(`claim_status.${status}_DETAILED.${details}`, `${t(`claim_status.${status}`)} - ${details}`)
            : t(`claim_status.${status}`);
    const chipText = amountRefunded ? `${statusText} · ${formatCurrency(amountRefunded / 100)}` : statusText;

    return (
        <Chip
            text={chipText}
            type={ChipType.NEUTRAL}
            color={colored ? claimStatusColorMap.get(status) : undefined}
            onClick={onClick}
        />
    );
}
